<template>
        <div class="aboutContent">
            <div class="aboutS">
                <div class="aboutBody">
                    <br>
                    <!-- 头像 -->
                    <div class="about-img">
                        <img src="../../assets/img/radish.jpg" alt="">
                    </div>
                    <br>
                    <!-- 名称 -->
                    <div class="about-name">
                        <span style="color: #f0f0f0">十一</span>
                    </div>
                    <br>
                    <!-- 联系方式 -->
                    <div class="contactinfo">
                        <ul>
                            <li>
                                <div class="qq">
                                    <svg class="icon" aria-hidden="true">
                                        <use xlink:href="#icon-qq1"></use>
                                    </svg>
                                    <div class="qq-img">
                                        <img src="http://img.fuqz.cc/qq.png" alt="">
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="wechat">
                                    <svg class="icon" aria-hidden="true">
                                        <use xlink:href="#icon-weixin"></use>
                                    </svg>
                                    <div class="wechat-img">
                                        <img src="http://img.fuqz.cc/weixin.png" alt="">
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="email">
                                    <svg class="icon" aria-hidden="true">
                                        <use xlink:href="#icon-youxiang1"></use>
                                    </svg>
                                    <div class="email-span">
                                        <span>az892035386@163.com</span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- 使用技术 -->
                    <div class="technology">
                        <h1 style="color: #0de9e9">技术：</h1>
                        <ul>
                            <li><h3>前端：vue2 + element</h3></li>
                            <li><h3>后端：springboot</h3></li>
                            <li><h3>数据库：mysql</h3></li>
                            <li><h3>构建工具：Maven、Tomcat、vue-cli</h3></li>
                            <li><h3>开发软件：IDEA、WebStorm</h3></li>
                            <li><h3>服务器：阿里云</h3></li>
                            <li><h3>图床：七牛云</h3></li>
                        </ul>
                        <h1 style="margin-top: 40px;color: #0de9e9">免责声明：</h1>
                        <ul>
                            <li><h3>本站所有文章为了记录工作、编程及生活随笔，可能有不正确的地方，仅供参考。</h3></li>
                            <li><h3>本站转载文章已注明出处，如有异议或侵权，请及时联系我处理，谢谢！</h3></li>
                            <li><h3>如有他人转载本站文章，请注明出处！</h3></li>
                            <li><h3>本站所有仅代表个人想法和意见。</h3></li>
<!--                            <li><h3></h3></li>-->
<!--                            <li><h3></h3></li>-->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    export default {
        name: "aboutContent",
        created() {
            this.$store.commit("updateRotation",false)
            /* 隐藏左侧 */
            this.$store.commit("updateLeftShow",true)
        }
    }
</script>

<style scoped>

</style>
